import React, { useState, useEffect }from "react";
import {TFL} from "../helpers/tfl"
import {Geo} from "../helpers/geo"
import Layout from "../components/Layout"
import { Map, GoogleApiWrapper, Marker} from 'google-maps-react'
import moment from 'moment'
import {Button, Form, Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas, far)

const tfl = new TFL()
const geo = new Geo()

const style = {
    //width: '100%',
    height: '400px',
    position: 'relative',
  }

const TflPage = (props) => {
    const [stopPoints, setStopPoints] = useState([])
    const [arrivals, setArrivals] = useState([])
    const [search, setSearch] = useState(null)
    const [markers, setMarkers] = useState(null)
    const [bounds, setBounds] = useState(null)
    const [radius, setRadius] = useState(20)

    const stopTypes = 'NaptanRailStation,NaptanMetroStation,NaptanBusCoachStation,NaptanPublicBusCoachTram,NaptanFerryPort'
    
    useEffect(() => {
        // if ("geolocation" in navigator) {
        //     navigator.geolocation.getCurrentPosition(position => {
        //         getStopPoints(position.coords.latitude, position.coords.longitude)
        //     })
        // }
        getCoords()
    },[props])
    
    useEffect(() => {
        let b = new props.google.maps.LatLngBounds()
        setMarkers(
            stopPoints.map((stop, index) => {
                b.extend({ lat: stop.lat, lng: stop.lon })
                if (stop.lines.length > 0) {
                    let icon = 'yellow'
                    if (stop.modes.length === 1) {
                        switch(stop.modes[0]) {
                            case 'tube':
                                icon = 'blue'
                                break;
                            
                            case 'bus':
                                icon = 'red'
                                break;

                            case 'tram':
                                icon = 'green'
                                break;

                            case 'dlr':
                                icon = 'ltblue'
                                break;

                            case 'river-bus':
                                icon = 'blue'
                                break;

                            default:
                                icon = 'yellow'
                                break;
                        }
                    }
                    //console.log(icon)
                    const marker = {
                        title: stop.commonName,
                        position: {
                            lat: stop.lat,
                            lng: stop.lon
                        },
                        stop: stop,
                        icon: {
                            url: `https://maps.google.com/mapfiles/ms/icons/${icon}-dot.png`
                        }
                    }
                    return <Marker key={index} {...marker} onClick={onMarkerClick}/>
                }
                
            })
        )
        setBounds(b)
        //console.log("markers",markers)
    },[stopPoints])


    const getCoords = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                getStopPoints(position.coords.latitude, position.coords.longitude)
            })
        }
    }


    const onMarkerClick = (event) => {
        //console.log('click',event)
        const lines = event.stop.lines.map(line=>line.id).join(',')
        //console.log(lines)
        tfl.getArrivals(lines,event.stop.id)
            .then(data => {
                data.data.sort((a,b) => new Date(a.expectedArrival).getTime() - new Date(b.expectedArrival).getTime())
                //console.log(data.data)
                setArrivals(data.data)
            })
    }


    const getStopPoints = (lat, lon) => {
        //console.log(radius)
        tfl.getNearMe(lon, lat, (radius*10)+1, stopTypes)
            .then(data => {
                setStopPoints(data.data.stopPoints)
            })
    }

    const handleSearch = (event) => {
        event.preventDefault()
        //console.log(search)
        geo.getLocation(search)
            .then(data => {
                //console.log(data)
                if (data) getStopPoints(data.latitude, data.longitude)
            })
    }

    const ArrivalRows = () => arrivals.map((row, index) => (
        <tr key={index}>
            <td>{moment(row.expectedArrival).diff(moment(),'minutes') === 0 ? "due" : moment(row.expectedArrival).diff(moment(),'minutes') +  " mins"}</td>
            <td>{row.platformName === 'null' ? row.stationName : row.platformName}</td>
            <td>{row.lineName}</td>
            <td>{row.destinationName}</td>
        </tr>
    ))


    const ArrivalTable = () => (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Expected</th>
                    <th>Platform / Stop</th>
                    <th>Line</th>
                    <th>Towards</th>
                </tr>
            </thead>
            <tbody>
                <ArrivalRows/>
            </tbody>
        </table>
    )

    const centerMoved = (mapProps, map) => {
        //console.log(map.getCenter().lat(),map.getCenter().lng())
        getStopPoints(map.getCenter().lat(),map.getCenter().lng())
    }


    return (
        <Layout title="TFL">
            <h1>TFL Live Stops & Updates</h1>
            <div className="mt-3 mb-3">
                <Form className="container-fluid shadow mb-3 p-3 bg-white" onSubmit={handleSearch}>
                    <Row>
                        <Col sm>
                            <Form.Label htmlFor="search">Search</Form.Label>
                            <Form.Control type="text" name="search" onChange={(e) => setSearch(e.target.value)}/>
                        </Col>
                        <Col sm>
                            <Form.Label htmlFor="radius">Radius</Form.Label>
                            <Form.Range name="radius" value={radius} onChange={(e) => setRadius(e.target.value)}/>
                        </Col>
                        <Col sm="auto" className="pt-4 text-end">
                            <Button type="submit">Search</Button>&nbsp;
                            <Button onClick={(e) => getCoords()}><FontAwesomeIcon icon={'location-crosshairs'} /></Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
            <Map
                className="shadow mb-3"
                containerStyle={style}
                google={props.google}
                bounds={bounds}
                onDragend={centerMoved}
            >
                {markers}   
            </Map>
            </div>
            <div className="mt-3 mb-3">
                <ArrivalTable/>
            </div>
        </Layout>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  })(TflPage)