import axios from 'axios'

export class Geo {
    instance
    _apiKey

    constructor() {
      this._apiKey = process.env.GATSBY_GEO_API_KEY
      this.instance = axios.create({
        baseURL: 'https://api-dev.exsertus.com/lab/geo',
      })
      this._initializeInterceptors()
    }

    _initializeInterceptors = () => {
      this.instance.interceptors.response.use(
        this._handleResponse,
        this._handleError,
      )
      this.instance.interceptors.request.use(
        this._handleRequest,
      )
    }

    // Use request interceptor add in the app_key querystring parameter to all requests
     _handleRequest = (config) => {
      config.url = `${config.url}${(config.url?.includes('?') ? '&' : '?')}access_key=${this._apiKey}&country=GB&region=london&output=json&limit=1`
      //console.log(config.url)
      return config
    }

    // Deconstruct the success response to just the 'data' object
    _handleResponse = ({data}) => data.data[0];

    _handleError = (error) => Promise.reject(error.message);

    // API methods

    getLocation = (address) => this.instance.get(`/forward?query=${address}`)
}
