import axios from 'axios'

export class TFL {
    instance
    _appKey

    constructor() {
      this._appKey = process.env.GATSBY_TFL_API_KEY
      this.instance = axios.create({
        baseURL: 'https://api.tfl.gov.uk',
      })
      this._initializeInterceptors()
    }

    _initializeInterceptors = () => {
      this.instance.interceptors.response.use(
        this._handleResponse,
        this._handleError,
      )
      this.instance.interceptors.request.use(
        this._handleRequest,
      )
    }

    // Use request interceptor add in the app_key querystring parameter to all requests
    _handleRequest = (config) => {
      config.url = `${config.url}${(config.url?.includes('?') ? '&' : '?')}app_key=${this._appKey}`
      //console.log(config.url)
      return config
    }

    // Deconstruct the success response to just the 'data' object
    _handleResponse = (data) => data;

    _handleError = (error) => Promise.reject(error.message);

    // API methods

    getArrivals = (ids, stopPointId) => this.instance.get(`/Line/${ids}/Arrivals/${stopPointId}`)

    getNearMe = (lon, lat, radius, stopTypes) => this.instance.get(`/StopPoint?stopTypes=${stopTypes}&lat=${lat}&lon=${lon}&radius=${radius}`)
}
